import { Vue, Component, Mixins } from 'vue-property-decorator';
import { ApiService } from '@/services/base/ApiService';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import { DataTableHeader, ValeurReferentielle, TypeValeurReferentielle } from '@/shared/models';
import { OperationDepot } from '@/models';
import { OperationDepotCriteria } from './models/OperationDepotCriteria.model';
import template from './StatutsOperations.Template.vue';
import { Getter, Action } from 'vuex-class';
import { getterKeyReferentiel, ReferentielStoreMethods } from '@/store/modules/referentiel/ReferentielStore';
import { DateHelper } from '@/shared/helpers';
import { isBoolean } from 'lodash';
import Confirm from '@/components/Confirm.vue';
import ApiAutocompleteHelper from '@/services/ApiAutocompleteHelper';
import CeeAutocomplete from '@/components/CeeAutocomplete.vue';
import { Depot } from '@/models/Depot.model';

@Component({
    ...template,
    name: 'StatutsOperations',
    components: {
        Confirm,
        CeeAutocomplete
    },
})
export default class StatutsOperations extends Mixins(GrilleMixin) {
    public $refs!: Vue['$refs'] & {
        confirm: {
            open: ((
                title: string | null,
                message: string | null,
                options: { color?: string; width?: number; zIndex?: number }
            ) => Promise<boolean>);
        };
    };

    // Liste des colonnes du tableau listant les opérations du dépôt choisi.
    private columns: DataTableHeader[] = [
        { text: 'N° Dossier', value: 'numero', align: 'center' },
        { text: 'Raison Sociale Bénéficiaire', value: 'raisonSocialeBeneficiaire', align: 'center' },
        { text: "Code de l'opération", value: 'code', align: 'center' },
        { text: 'Volume CEE Classique (MWh cumac)', value: 'volumeCeeClassique', align: 'center' },
        { text: 'Volume CEE Précarité (MWh cumac)', value: 'volumeCeePrecarite', align: 'center' },
        { text: "Date début travaux", value: 'dateDebutTravaux', align: 'center' },
        { text: "Date fin des travaux", value: 'dateFinTravaux', align: 'center' },
        { text: 'Statut PNCEE', value: 'statutPncee', align: 'center' },
    ];



    // Loaders.
    public loading: boolean = true;
    public loadingUpdateSelected: boolean = false;
    public loadingUpdateDepot: boolean = false;
    public loadingListeDepot: boolean = false;

    // Liste des opérations du dépôt sélectionnées.
    public selected: OperationDepot[] = new Array<OperationDepot>();

    // Permet de savoir si c'est un administrateur informatique.
    public estAdministrateurInformatique: boolean = false;

    // Liste des statuts PNCEE.
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.StatutPncee))
    public statutsPNCEE: ValeurReferentielle[];

    // Permet de récupérer les données référentielles.
    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
    public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>;

    public listeDepot: Depot[] = [];

    public getAllNomDepot(): void {
        this.loadingListeDepot = true;
        this.$http.get(`/depot/obtenirListeNomsDepots`)
            .then((result) => {
                const { data: { data: responseData } } = result as ({ data: { data: Depot[], isError: boolean, messages: any[] } });
                if (!!responseData && responseData.length > 0) {
                    this.listeDepot = responseData;
                }
            }).finally(() => this.loadingListeDepot = false);
    }

    // Liste des noms de dépôts. 
    public async nomsDepotPromise(recherche: string): Promise<Depot[]> {
        const data = await ApiAutocompleteHelper.getAutocompletePromise<Depot>(recherche, `depot/obtenirListeNomsDepots`);
        if (data.length === 1) {
            this.operationDepotCriteria.nomDepot = data[0].nom;
            this.getData();
        }
        return data;
    }   

    // Statut PNCEE choisi.
    public statutPNCEEChoisi: number = 0;

    // Liste des opérations d'un dépôts.
    public operationsDepot: OperationDepot[];

    /**
     * Le nombre total d'utilisateurs.
     */
    public totalOperationsDepot: number = 0;

    /**
     * Le critère de recherche de la grille.
     */
    public operationDepotCriteria: OperationDepotCriteria = new OperationDepotCriteria();

    /**
     * constructor.
     */
    constructor() {
        super();
    }

    public initialize() {        
        this.operationsDepot = new Array<OperationDepot>();
    }
  
    /**
     * Hook qui se déclenche lorsque le composant est créé.
     */
    public created() {
        this.initialize();
        this.getData();
        this.getAllNomDepot();
    }

    /**
     * Hook qui se déclenche lorsque le composant est monté.
     */
    public mounted() {
        this.getValeursReferentielles(TypeValeurReferentielle.StatutPncee);       
    }

    /**
     * Méthode de récupération des opérations du dépôts choisi.
     */
    public getData() {        
        const fetchDepotApi = new ApiService<OperationDepot>('depot/obtenirListeOperationsDepot');       
        this.loading = true;
        fetchDepotApi.getWhere(this.operationDepotCriteria.queryString).then((response) => {
            this.operationsDepot = response.data.data;
            this.$set(this.pagination, 'totalItems', this.totalOperationsDepot);
        }).finally(() => this.loading = false);
    }

    /**
     * Rafraîchissement des éléments du tableau des opérations du dépôts.
     */  
    public refreshOperationsDepot() {
        this.getData();
    } 

    /**
     * Mise à jour du statut PNCEE des opérations sélectionnées.
     */
    public async majStatutPnceeOperationsSelectionnees() {
        if (await this.$refs.confirm.open(
            'Confirmation',
            `Confirmez-vous la modification en masse des statuts PNCEE des opérations sélectionnées ?`,
            null)) {
            const statutsPnceeApiService = new ApiService<OperationDepotCriteria>('/depot/majStatutPnceeOperationsSelectionnees');
            if (this.statutPNCEEChoisi > 0) {
                this.loadingUpdateSelected = true;

                const selectedIds: number[] = this.selected.map(({ id }) => id);
                this.operationDepotCriteria.selectedOperationsIds = selectedIds;
                this.operationDepotCriteria.statutPnceeId = this.statutPNCEEChoisi;

                statutsPnceeApiService.post(this.operationDepotCriteria).then((reponse: any) => {
                    if (reponse && reponse.data !== null && !(reponse.data.hasOwnProperty('isError') && isBoolean(reponse.data.isError))) {
                        // Succès.
                    }
                }).finally(() => {
                    this.loadingUpdateSelected = false;
                    this.refreshOperationsDepot();
                });
            }
        }
    }
    /**
     * Mise à jour du statut PNCEE des opérations du dépôts sélectionnés.
     */
    public async majStatutPnceeOperationsDepot() {
        if (await this.$refs.confirm.open(
            'Confirmation',
            `Confirmez-vous la modification en masse des statuts PNCEE des opérations du dépôt choisi ?`,
            null)) {
            const statutsPnceeApiService = new ApiService<OperationDepotCriteria>('/depot/majStatutPnceeOperationsDepot');
            if (this.statutPNCEEChoisi > 0 && this.operationDepotCriteria.nomDepot !== '') {
                this.loadingUpdateDepot = true;
               
                this.operationDepotCriteria.statutPnceeId = this.statutPNCEEChoisi;

                statutsPnceeApiService.post(this.operationDepotCriteria).then((reponse: any) => {
                    if (reponse && reponse.data !== null && !(reponse.data.hasOwnProperty('isError') && isBoolean(reponse.data.isError))) {
                        // Succès.
                    }
                }).finally(() => {
                    this.loadingUpdateDepot = false;
                    this.refreshOperationsDepot();
                });
            }
        }
    }
    /**
     * Formatage de date.
     * @param value la date.
     */
    public formatDate(value: string): string {
        const date = DateHelper.format(value);

        return (date !== 'Invalid date')
            ? date
            : '-';
    }
}

