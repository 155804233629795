import { CriteriaBase } from '@/shared/models';

/**
 * Modèle pour les critères du choix du dépôts fait par l'administrateur.
 * */
export class OperationDepotCriteria extends CriteriaBase {

    /**
     * Le nom du dépôt choisi.
     */
    public nomDepot: string = '';

    /**
     * L'id du statut PNCEE choisi.
     */
    public statutPnceeId: number = 0;

    /**
     * Liste des ids des opérations sélectionnées du dépôt.
     */
    public selectedOperationsIds: number[] = new Array<number>();

}